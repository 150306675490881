<template>
  <b-container fluid>
    <b-row class="text-center mb-4" align-h="center" v-if="!permissions.read">
      <b-col cols="1">
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="loading && permissions.read">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="row text-white bg-kpmg-blue" v-if="!loading">
      <b-col align-self="center" cols="2" class=" cols text-center" align-v="center">
        <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Controls</h1>
      </b-col>
      <b-col cols="8" align-self="center" align-v="center" class="search text-center cols justify-content-md-center">
        <b-input-group>
          <b-form-input v-model="filter" placeholder="Type to Search" />
            <b-btn :disabled="!filter" @click="filter = ''">clear</b-btn>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="text-white bg-kpmg-blue mt-0" v-if="!loading && permissions.read && dataReady " >
      <b-col align-self="center" cols="2" class="cols text-center" align-v="center"></b-col>
      <b-col align-self="center" cols="8" class="cols text-center" align-v="center">
          <a v-for="row in select" :key="'select-id' + row.property" class="mr-2 mb-3 mt-0 text-center" flex>
            <b-button v-b-toggle="'sidebar-' + row.property" class="filter-button">{{row.property}} <b-badge v-if="$data[row.selected].length != 0" variant="light">{{$data[row.selected].length}}</b-badge></b-button>
          </a>
          <b-btn class="filter-button" @click="filterReset">reset filters</b-btn>
      </b-col>
      <b-col align-self="center" cols="2" class="cols text-center" align-v="center"></b-col>
    </b-row>
    <b-row>
      <b-col>
            <div v-for="row in select" :key="'select-id' + row.property" class="mr-2 mb-3">
              <b-sidebar :id="'sidebar-' + row.property" :title="row.property.charAt(0).toUpperCase() + row.property.slice(1)" backdrop backdrop-variant="transparent" shadow>
                <div class="px-3 py-2 text-center" align-self="center" align-v="center">
                  <b-button-group b-button-group class="mt-3 text-center">
                    <b-button variant="outline-primary" class="text-center" v-on:click="setSelection('all', row.property)">all</b-button>
                    <b-button variant="outline-primary" class="text-center" v-on:click="setSelection('none', row.property)">none</b-button>
                  </b-button-group>
                  <b-card class="m-3">
                  <b-form-checkbox-group
                          v-model="$data[row.selected]"
                          :options="$data[row.options]"
                          button-variant="light"
                          size="md"
                          class="d-flex flex-column mx-auto text-left"
                          switches
                    ></b-form-checkbox-group>
                  </b-card>
                </div>
              </b-sidebar>
            </div>
          <b-form inline>
            <span class="mr-2"><strong>Showing {{rowsShow.length}} out of {{rows.length}} controls</strong></span>
            <b-button variant="outline-primary" @click="filterReset" class="mr-2 button">reset filters</b-button>
            <b-button variant="outline-primary" @click="download" class="mr-2 button">download</b-button>
            <b-button class="mr-2 button" v-if="permissions.add" variant="outline-primary" :to="{ name: 'FormNew', params: {model: 'control' }}">add control</b-button>
            <b-form-select class ="button" v-model="viewSelected" :options="viewOptions" id="view-select"></b-form-select>
          </b-form>
        <div v-if="viewSelected === 'table'">
          <b-table style="font-size: 12px" striped hover :fields="fields" :items="rowsShow"></b-table>
        </div>
        <div v-if="viewSelected === 'matrix'">
          <matrix
            :rows="rowsShow"
            :dictionaries="dictionaries"
          ></matrix>
        </div>
        <div v-if="viewSelected === 'list_simple'">
          <b-card>
            <div class="mb-3" v-for="row in rowsShow" :key="row.id">
              <control-simple
                :item="row"
                :username="user.username"
              />
            </div>
          </b-card>
        </div>
        <div v-if="viewSelected === 'list_full'">
          <b-card class="mb-3" v-for="row in rowsShow" :key="row.id">
            <control
              :item="row"
              :username="user.username"
            />
          </b-card>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import striptags from 'striptags'
import zipcelx from 'zipcelx'

import Control from '@/components/Control.vue'
import ControlSimple from '@/components/ControlSimple.vue'
import Matrix from '@/components/Matrix.vue'

const staticDataProperties = [
  { name: 'areas', property: 'areas', graph: 'areas' },
  { name: 'risks', property: 'risks', graph: 'controlrisks' },
  { name: 'status', property: 'status', graph: 'controlstatus' },
  { name: 'themes', property: 'themes', graph: 'controlthemes' },
  { name: 'projects', property: 'projects', graph: 'projects' }
]

export default {
  name: 'Controls',
  components: {
    Control,
    ControlSimple,
    Matrix
  },
  computed: {
    rowsShow: function () {
      let rows = []
      rows = this.rows
      _.each(this.select, row => {
        if (this[row.selected].length > 0) {
          rows = _.filter(rows, x => {
            let result = false
            if (Array.isArray(x[row.graph])) {
              _.each(this[row.selected], item => {
                if (_.find(x[row.graph], ['id', item])) {
                  result = true
                }
              })
            } else {
              if (x[row.graph].id === parseInt(this[row.selected])) {
                result = true
              }
            }
            return result
          })
        }
      })
      if (this.filter.length > 2) {
        rows = rows.filter(x => {
          const term = x.name + ' ' + x.objective + ' ' + x.description
          return term.toUpperCase().match(this.filter.toUpperCase())
        })
      }
      _.each(rows, row => {
        row.plain_description = striptags(row.description)
        row.plain_areas = row.areas.length > 0 ? row.areas.map(x => x.name) : ''
        row.plain_risks = row.controlrisks.length > 0 ? row.controlrisks.map(x => x.name) : ''
        row.plain_themes = row.controlthemes.length > 0 ? row.controlthemes.map(x => x.name) : ''
        row.plain_status = row.controlstatus.name
      })
      return rows
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'controls', action: 'open controls' })
    this.permissions.read = ac.can(this.user.acgroups).readAny('control').granted
    this.permissions.add = ac.can(this.user.acgroups).createAny('control').granted
    if (this.permissions.read) {
      this.load()
    }
  },
  data () {
    const data = {
      dictionaries: [],
      dataReady: false,
      permissions: {
        add: false,
        read: false
      },
      fields: [
        { key: 'id', label: 'id' },
        { key: 'name', label: 'Name' },
        { key: 'objective', label: 'Objective' },
        { key: 'plain_description', label: 'Description' },
        { key: 'plain_areas', label: 'Areas' },
        { key: 'plain_risks', label: 'Risks' },
        { key: 'plain_themes', label: 'Themes' },
        { key: 'plain_status', label: 'Status' }
      ],
      filter: '',
      loading: true,
      rows: [],
      select: [],
      staticData: null,
      viewOptions: [
        { value: 'list_simple', text: 'list simple' },
        { value: 'list_full', text: 'list full' },
        { value: 'table', text: 'table' },
        { value: 'matrix', text: 'matrix' }
      ],
      viewSelected: 'list_simple'
    }
    _.each(staticDataProperties, property => {
      const selected = property.property + 'Selected'
      const options = property.property + 'Options'
      data[selected] = []
      data[options] = []
    })
    return data
  },
  methods: {
    download: function () {
      this.$logger.debug('loading started')
      const data = []
      const fields = [
        { field: 'id', name: 'control id' },
        { field: 'name', name: 'control name' },
        { field: 'objective', name: 'control objective' },
        { field: 'description', name: 'control description' },
        { field: 'controlthemes', name: 'themes' },
        { field: 'controlrisks', name: 'risks' },
        { field: 'areas', name: 'responsible functions' },
        { field: 'controlstatus', name: 'control drafting status' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      _.each(this.rowsShow, annotation => {
        const row = _.map(fields, field => {
          if (field.field === 'controlstatus') {
            return { value: annotation.controlstatus.name, type: 'string' }
          }
          if (field.field === 'description') {
            return { value: striptags(annotation.description), type: 'string' }
          }
          if (field.field === 'controlthemes') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'areas') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'controlrisks') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          return { value: annotation[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'controls', sheet: { data: data } }
      zipcelx(config)
    },
    filterReset: function () {
      _.each(staticDataProperties, property => {
        const selected = property.property + 'Selected'
        this.$data[selected] = []
      })
      this.filter = ''
    },
    load: async function () {
      this.loading = true
      try {
        this.staticData = await this.$Amplify.API.get('cosmos', '/controls/staticdata')
        console.log(this.staticData)
        _.each(staticDataProperties, property => {
          const selected = property.property + 'Selected'
          const options = property.property + 'Options'
          this.$data[options] = this.staticData[property.property].map(x => {
            return { value: x.id, text: x.name }
          })
          const selectRow = {
            name: property.name,
            graph: property.graph,
            property: property.property,
            selected: selected,
            options: options
          }
          this.select.push(selectRow)
          const staticRow = {
            name: property.name,
            graph: property.graph,
            data: this.staticData[property.property].map(x => x.name)
          }
          this.dictionaries.push(staticRow)
        })
        this.rows = await this.$Amplify.API.get('cosmos', '/controls')
        this.loading = false
        this.dataReady = true
      } catch (e) {
        this.$logger.warn('saved ERROR: ', e)
      }
    },
    setSelection: function (button, selectionName) {
      if (button === "none") {
        this[selectionName + 'Selected'] = []
      }
      if (button === 'all') {
        this[selectionName + 'Selected'] = []
        _.each(this[selectionName + 'Options'], x => {
          this[selectionName + 'Selected'].push(x.value)
        })
      }
    }
  }
}
</script>
<style>
body {
  padding-top: 100px;
}
h1 {
  margin: 15px;
}
.b-sidebar {
  width: auto;
}
.button {
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.filter {
  max-width: 253px;
}
.filter-button {
  margin-left: 15px;
  margin-top: -15px;
  margin-bottom: 15px;
}
</style>
